var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "delivery-area-card" }, [
    _c("div", { staticClass: "fw500 flex bet a-center" }, [
      _c("div", {
        style: { "margin-top": "10px", "margin-right": "15px" },
        domProps: { textContent: _vm._s(_vm.item.name) },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "delivery-area-card__main",
        style: { width: "100%", display: "flex", "margin-right": "45px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "delivery-area-card__main_main",
            style: { width: "100%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "delivery-area-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Minimum")) },
                }),
                _vm.restaurantCurrencyInfo === null
                  ? _c("div", {
                      domProps: { textContent: _vm._s("₪" + _vm.item.min) },
                    })
                  : _vm._e(),
                _vm.restaurantCurrencyInfo !== null
                  ? _c("div", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.restaurantCurrencySymbol + _vm.item.min
                        ),
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "delivery-area-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Price")) },
                }),
                _vm.restaurantCurrencyInfo === null
                  ? _c("div", {
                      domProps: { textContent: _vm._s("₪" + _vm.item.price) },
                    })
                  : _vm._e(),
                _vm.restaurantCurrencyInfo !== null
                  ? _c("div", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.restaurantCurrencySymbol + _vm.item.price
                        ),
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "delivery-area-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Delivery time")) },
                }),
                _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.item.time + " " + _vm.$t("Minutes")
                    ),
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "delivery-area-card__main_name" },
              [
                _c("swich-button", {
                  style: { "margin-top": "10px" },
                  attrs: { value: _vm.item.active },
                  on: { input: _vm.data.inputActive },
                }),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }